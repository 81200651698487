.btn-group {
  display: flex;
  gap: 10px;
  margin-top: 60px;
}
.form-name {
  font-size: 18px;
  margin-bottom: 40px;
}
.otp-input {
  display: block !important;
  margin-right: auto;
  margin-left: auto;
}
a {
  color: black;
  text-decoration: underline;
}
a:hover {
  color: black;
}
.cancel-btn-visiblity {
  visibility: hidden;
}
.cancel-btn-wrapper {
  position: relative;
}
.cancel-btn-wrapper .cancel-btn {
  width: calc(50% - 5px);
  position: absolute;
  left: 0;
  bottom: 100%;
}
