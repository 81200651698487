.btn-group {
  display: flex;
  gap: 10px;
  margin-top: 60px;
}
.form-name {
  font-size: 18px;
  margin-bottom: 40px;
}
.resendBtn {
  display: flex;
  justify-content: center !important;
  gap: 10px;
  margin-top: 17px;
}
.resendBtn button {
  background-color: transparent;
  color: #001323;
  padding: 0;
  border: 0;
  width: auto;
}

.resendBtn button:disabled {
  color: #bababb;
}
