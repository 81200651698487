.loader-container {
    background: #ffffffcc;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.dots {
    width: 165px;
    height: 60px;
    background: radial-gradient(circle closest-side,#e15b64 90%,#0000) 0% 50%, radial-gradient(circle closest-side,#e15b64 90%,#0000) 50% 50%, radial-gradient(circle closest-side,#1c417a 90%,#0000) 100% 50%;
    background-size: calc(100%/3) 36px;
    background-repeat: no-repeat;
    animation: dots-7ar3yq 1s infinite linear;
 }
 
 @keyframes dots-7ar3yq {
    20% {
       background-position: 0%   0%, 50%  50%,100%  50%;
    }
 
    40% {
       background-position: 0% 100%, 50%   0%,100%  50%;
    }
 
    60% {
       background-position: 0%  50%, 50% 100%,100%   0%;
    }
 
    80% {
       background-position: 0%  50%, 50%  50%,100% 100%;
    }
 }